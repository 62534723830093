import React from 'react';
import Search from './Search.js';

class Header extends React.Component
{
  render()
  {
    return (
        <div class="navbar-area">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-lg-12">
								<nav class="navbar navbar-expand-lg">
									<a class="navbar-brand" href="#logo">
										<img src="assets/img/logo-1.png" alt="Logo" />
									</a>
									<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
										<span class="toggler-icon"></span>
										<span class="toggler-icon"></span>
										<span class="toggler-icon"></span>
									</button>
									<div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
										<ul id="nav" class="navbar-nav ml-auto">
											<li class="nav-item">
													<a class="page-scroll" href="/home">Home</a>
											</li>
											<li class="nav-item">
												<a class="page-scroll" href="/services">Quran Chapter</a>
											</li>
											<li class="nav-item">
												<a class="page-scroll" href="/about">Hadith</a>
											</li>										
										</ul>
									</div>
								<Search />
							</nav>
					</div>
			</div>
		</div>
      </div>
    );
  }
}

export default Header;