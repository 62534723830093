import React, { useState, useEffect } from 'react';
import { Container } from "react-bootstrap";
 
const Qurandata = () => {
 
  const [chapter, setChapter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);

  /***************/
  const [chapterUpdate, setchapterUpdate] = useState([]);

  //const [surah_title, setChapteTitle] = useState('In the name of God, the Most Gracious, the Most Merciful');
 
  let fweight = [{ value: "Normal", label: "normal" }, { value: "Bold", label: "bold" }];

  let fsize = [];

  for (let i = 14; i < 30; i++) { 
    fsize.push({  
      label: i + 1,
      value: i + 1,
    })
  };
 
  const [TxtClass, setTxtClass] = useState('english-Text');
  
  const lang = [
    { value: "English", label: "en.asad" },
    { value: "Urdu", label: "ur.jalandhry" },
    { value: "Arabic", label: "ar.alafasy" }, 
  ];
 // quran-uthmani
  const [setvalue, setStateValue] = useState({chapter: 1, verse: "", language: "en.asad", fontsize: 22, useft:'normal'})

  const [txtdirection, setdir] = useState('ltr');  

  const [identifier, setidentifier] = useState("en.asad"); 
  
  const [usesize, setSize] = useState(17); 
  const [useft, setFontwt] = useState('normal'); 

  let defverse = [];

  for (let i = 0; i < 7; i++) { 
    defverse.push({  
      label: i + 1,
      value: i + 1,
    })
  };

  const [chapterVerse, setVerseData] = useState(defverse);
  
  const [getTitle, setTitleData] = useState({chapter: 1, title: 'The Opening'});

  let txt = '';
  /**************************/
  const onChangeWeight = (e) => {
  let useft = e.target.value;
   setFontwt(useft);
    let name = e.target.name;
    let val = e.target.value;
    setStateValue({...setvalue, [name]:val});
  }

  /************Font Change function load******************/

  const onChangefont = (e) => {
    let usesize = e.target.value;
    setSize(usesize);

    let name = e.target.name;
    let val = e.target.value;
    setStateValue({...setvalue, [name]:val});
  }
  /************Default function load******************/
  useEffect(() => {
    fetchChapter(); 
   }, []);

  const fetchChapter = () => {
      setIsLoading(true)
    try {
        fetch('http://api.alquran.cloud/v1/surah')
        .then(res => res.json())
          .then(
            (result) => {
            setChapter(result.data)
            setIsLoading(false)
          }
        );
        setSize(18);
    } catch (error) {
      console.error();
    }
    try {
    fetch('http://api.alquran.cloud/v1/surah/1/en.asad')
      .then(res => res.json())
        .then(
          (result) => {
            let quran_text = result.data.ayahs;
            var items = quran_text.map((item) => {
              //let def_title = 'In the name of God, The Most Gracious, The Dispenser of Grace:';
              //item.text.replace(def_title, "");
              let txt = '(' + item.number + ') - '+ item.text;
              return {number:item.number, text: txt};
            });

            setChapterData(items);

            setchapterUpdate(items);

            setdir('ltr');

            let newverse = [];
              for (let i = 0; i < result.data.numberOfAyahs; i++) { 
                newverse.push({  
                  label: i + 1,
                  value: i + 1,
                })
              };
            setVerseData(newverse); 
        }
      );
    } catch (error) {
      console.error();
    }
 }

 /************Chapter Change function load******************/

 const getChapter = (e) => {
    const cid = e.target.value;

    let name = e.target.name;
    let val = e.target.value;
    setStateValue({...setvalue, [name]:val});

    setIsLoading(true);
    
     fetch('http://api.alquran.cloud/v1/surah/'+cid+'/'+identifier)
     .then(res => res.json())
      .then(
         (result) => {

           let quran_text = result.data.ayahs;

          let num = 1;
          var items = quran_text.map((item) => {
            if(result.data.edition.language === "ur") {
               txt = item.text + '﴿'+ num +'﴾';
            } 
            if(result.data.edition.language === "ar") {
               txt = item.text + '﴿'+ num +'﴾';
            } else {
               txt = '(' + num + ') - '+ item.text;
            } 
            num++;
            return {number:item.number, text: txt};
          });
 
          setChapterData(items);

          setchapterUpdate(items);

          if(result.data.edition.language === "ur") {
           // let surah_title = 'شروع اﷲ کے نام سے جو بڑا مہربان، نہایت رحم والا ہے';
            setTitleData({...getTitle, chapter: cid, title: result.data.name});
            setdir('rtl');
            setTxtClass('urdu-txtuse');
            setSize("24");
          //  setChapteTitle(surah_title);
          } else if(result.data.edition.language === "ar") {
           // let surah_title = 'بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ ٱلرَّحِيمِ';
            setTitleData({...getTitle, chapter: cid, title: result.data.name});
            setdir('rtl');
            setTxtClass('arabic-txt'); 
            setSize("24");
           // setChapteTitle(surah_title);
          } else {
            setTitleData({...getTitle, chapter: cid, title: result.data.englishName});
            setdir('ltr');
            setSize("18");
            setTxtClass('english-Text');
          }
          
          setidentifier(setvalue.language);

           let newverse = [];
            for (let i = 0; i < result.data.numberOfAyahs; i++) { 
              newverse.push({  
                label: i + 1,
                value: i + 1,
              })
            };
           setVerseData(newverse);

          
           setIsLoading(false);
      }
    )
}



const GetChapterbyVerse = (event) =>{
  let name = event.target.name;
  let index = parseInt(event.target.value);


  setStateValue({...setvalue, [name]:index});
  index -= 1;
  var items = chapterData.slice(index, chapterData.length).map((item) => {
   // let def_title = 'بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ ٱلرَّحِيمِ';
   // item.text.replace(def_title, "");
    return {number:item.number, text: item.text};
  });  
  setchapterUpdate(items);
}

//console.warn(setvalue);

const getLanguage = (e) => {
  const identifier = e.target.value;
 
  let name = e.target.name;
  let val = e.target.value;
  setStateValue({...setvalue, [name]:val});
  setIsLoading(true);
  fetch('http://api.alquran.cloud/v1/surah/'+setvalue.chapter+'/'+identifier)
   .then(res => res.json())
   .then(
      (result) => {

        let quran_text = result.data.ayahs;

        let num = 1;
        var items = quran_text.map((item) => {
          if(result.data.edition.language === "ur") {
              txt = item.text + '﴿'+ num +'﴾';
          } 
          if(result.data.edition.language === "ar") {
              txt = item.text + '﴿'+ num +'﴾';
          } 
          if(result.data.edition.language === "en") {
              txt = '(' + num + ') - '+ item.text;
          } 
          num++;
          return {number:item.number, text: txt};
        });
      
       setChapterData(items);
        
       setchapterUpdate(items);

       if(result.data.edition.language === "ur") {
         // let surah_title = 'شروع اﷲ کے نام سے جو بڑا مہربان، نہایت رحم والا ہے';
          setTitleData({...getTitle, chapter: setvalue.chapter, title: result.data.name});
          setdir('rtl');
          setTxtClass('urdu-txtuse');
          setSize('24');
         // setChapteTitle(surah_title);
        } else if(result.data.edition.language === "ar") {
          //let surah_title = 'بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ ٱلرَّحِيمِ';
          setTitleData({...getTitle, chapter: setvalue.chapter, title: result.data.name});
          setdir('rtl');
          setTxtClass('arabic-txt');
          setSize('22');
         // setChapteTitle(surah_title);
        } else {
          setTitleData({...getTitle, chapter: setvalue.chapter, title: result.data.englishName});
          setdir('ltr');
          setTxtClass('english-Text');
          setSize('18');
        }
 
       setidentifier(identifier);
       /*****Set Verse*******/
        
        let newverse = [];
         for (let i = 0; i < result.data.numberOfAyahs; i++) { 
           newverse.push({  
             label: i + 1,
             value: i + 1,
           })
         };
        setVerseData(newverse); 
        /*****Close Verse*******/
        setIsLoading(false);
   }
 )
}

/*
const divStyle={
  overflowY: 'scroll',
  maxHeight: "410px", 
  height:'500px',
  position:'relative',
};*/

// Sticky Menu Area
useEffect(() => {
    window.addEventListener('scroll', isSticky);
  return () => {
    window.removeEventListener('scroll', isSticky);
  };
});

/* Method that will fix header after a specific scrollable */
const isSticky = (e) => {
  const header = document.querySelector('.header-section');
  const scrollTop = window.scrollY;
  scrollTop >= 150 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
};
console.warn(chapterUpdate);
return(
  <React.Fragment>
    <Container>
      <div className="row col-md-12 mt-3">
       <div className="header-section  d-xl-block">
          <div className="col-md-3  mt-10">
                <select className="form-control" name="chapter" value={setvalue.chapter}  onChange={getChapter}  >
                  <option value="">Select Chapter</option>
                {
                  chapter.map( item => (
                      <option key={item.number} value={item.number}>{item.number} - {item.englishName} ({item.englishNameTranslation})</option>
                  )
                )}
              </select>
          </div>
          <div className="col-md-3  mt-10">
             <select className="form-control" name="verse" onChange={GetChapterbyVerse} value={setvalue.verse} >
                <option value="">Select Verse</option>
                {
                  chapterVerse.map( item => (
                    <option key={item.value} value={item.value}> {item.label}</option>
                )
                )}
              </select>
       </div>
       <div className="col-md-3 mt-10">
        <select className="form-control form-group" name="language" onChange={getLanguage} value={setvalue.language} >
          <option value="">Select Language</option>
          {
           lang.map( item => (
            <option key={item.label} value={item.label}> {item.value}</option>
            )
          )}
          </select>
        </div>
        <div className="col-md-3 mt-10">
          <select className="form-control form-group" name="fontsize" onChange={onChangefont} value={setvalue.fontsize} >
            <option value="">Select Size</option>
            {
            fsize.map( item => (
              <option key={item.label} value={item.label}> {item.value}</option>
              )
            )}
          </select>
        </div>
        <div className="col-md-3 mt-10">
          <select className="form-control form-group" name="fontwt" onChange={onChangeWeight} value={setvalue.fontwgt} >
            <option value="">Font Set</option>
            {
            fweight.map( item => (
              <option key={item.label} value={item.label}> {item.value}</option>
              )
            )}
          </select>
        </div>
    </div>
  </div>
    <div style={{ textAlign: 'center' , color: '#000'}} >{isLoading && <p>Loading...</p>}</div>
    <div className="pt-1"  dir={txtdirection}><h4 className={TxtClass}>{getTitle.chapter} - {getTitle.title}</h4></div>
      <div className="col-md-12 mt-10 scroll-use" dir={txtdirection}  >
           <ul>{chapterUpdate.map((item, index) => 
              <li key={index} className={TxtClass} style={{fontSize: usesize+'px', fontWeight: useft}}>
                { item.text } 
              </li>
            )}
        </ul>
      </div>
    </Container>
  </React.Fragment>
  );
}

export default Qurandata;