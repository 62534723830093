import './App.css';
import Headers from './Header';
import Quranpage from  './Quranpage';
import Footers from './Footer';
import React, { useEffect, useState } from "react";
 
function App() {

  // React State
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Access count value from session storage
    var pageView = sessionStorage.getItem("pageView");
    if (pageView == null) {
      // Initialize page views count
      pageView = 1;
    } else {
      // Increment count
      pageView = Number(pageView) + 1;
    }
    // Update session storage
    sessionStorage.setItem("pageView", pageView);
    setCount(pageView);
  }, []); //No dependency to trigger in each page load

  return (
    <div>
      <Headers/>
      <Quranpage/>
        <a href="/" class="scroll-top btn-hover">
          <i class="lni lni-chevron-up"></i>
        </a>
        <p style={{ display: 'none'}} >{count}</p>
       <Footers/>     
    </div>     
  );
}

export default App;