import React from "react";
import Quranchapter from './QuranChapter';

class Quranpage extends React.Component {
     render(){
        return (
            <section id="home" class="hero-section">
            <div class="hero-shape">
                <img src="assets/img/hero/hero-shape.svg" alt="" class="shape" />
            </div>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12" >
                        <Quranchapter /> 
                    </div>
                     {/* <div class="col-lg-6">
                            <div class="hero-content">
                                <h1 class="wow fadeInUp" data-wow-delay=".2s">Daily <span>Hadith & Quran </span> </h1>
                                <p class="wow fadeInUp" data-wow-delay=".4s">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                                </p>
                                <div class="main-btn btn-hover wow fadeInUp" data-wow-delay=".6s">Download App</div>
                            </div>
                        </div>*/}
                </div>
            </div>
         </section>
        )
    }
}

export default Quranpage;