import React from 'react';

class Footer extends React.Component
{
  render()
  {
    return (
        <div id="footer" class="footer mt-10 pt-100 pb-70">
		<div class="footer-shape">
				<img src="assets/img/footer/footer-left.svg" alt="" class="shape shape-1" />
				<img src="assets/img/footer/footer-right.svg" alt="" class="shape shape-2" />
		</div>
		<div class="container">
			<div class="row">
				<div class="col-lg-3 col-md-6">
					<div class="footer-widget wow fadeInUp" data-wow-delay=".2s">
						<div class="logo">
							<a href="/home"><img src="assets/img/logo-1.png" alt="logo" /></a>
						</div>
						<div class="download-btns">
							<a href="/down">
							<span class="icon"><i class="lni lni-play-store"></i></span>
							<span class="text">GET IT ON <b>Play Store</b> </span>
							</a>
						</div>
					</div>
				</div>
      <div class="col-lg-3 col-md-6">
				<div class="footer-widget wow fadeInUp" data-wow-delay=".4s">
					<h3>Menu</h3>
					<ul class="links">
						<li>
							<a href="/home">Home</a>
						</li>
						<li>
							<a href="/quran">Quran Chapter</a>
						</li>
						<li>
							<a href="/hadith">Hadith</a>
						</li>
					</ul>
				</div>
      </div>
      <div class="col-lg-3 col-md-6">
      <div class="footer-widget wow fadeInUp" data-wow-delay=".6s">
				<h3>Hadith</h3>
				<ul class="links">
					<li>
						<a href="/muslim">Sahi Muslim</a>
					</li>					
				</ul>
      </div>
      </div>
      <div class="col-lg-3 col-md-6">
				<div class="footer-widget wow fadeInUp" data-wow-delay=".8s">
					<h3>Contact Us</h3>
				</div>
      </div>
		</div>
	</div>
	</div>
    );
  }
}

export default Footer;