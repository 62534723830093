import React from "react";

const SearchBar = () => (
    <div class="col-lg-4 ml-5">
        <form action="/" method="get">
            <div className="list filtered-list">
                <input className="filter form-control"  type="text" placeholder="Search Quran Text..."/>
            </div> 
        </form>
    </div>
);

export default SearchBar;